<template>
  <div class="mt-5">
    <portal to="breadcrumb">
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </portal>
    <div class="">
      <div class="bg p-3">
        <div
          class="
            d-flex
            flex-wrap
            mb-4
            justify-content-between
            align-items-center
          "
        >
          <div class="my-1 flex-fill">
            <h4>Liste des subventions</h4>
          </div>
          <form class="d-flex my-1">
            <div class="input-group mb-3 me-4">
              <input
                type="text"
                class="form-control"
                placeholder=""
                aria-label="Example text with button addon"
                aria-describedby="button-query"
              />
              <button
                type="submit"
                class="style_btn btn btn-primary"
                id="button-query"
              >
                <i class="bi bi-search"></i>
              </button>
            </div>
            <subvenir-besoin-edit
              ref="SubvenirBesoinEditor"
              @saved="updateOrCreateSubvenirBesoin"
              btnText="Créer un subvenirbesoin"
              :item="activeSubvenirBesoin"
              title="Ajouter un subvenirbesoin"
            ></subvenir-besoin-edit>
          </form>
        </div>
        <div class="bg-white p-3 border shadow">
          <div class="table-responsive">
            <b-table
              show-empty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              class="align-middle"
              striped
              :items="tableData"
            >
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(actions)="data">
                <div class="d-flex justify-content-end">
                  <a
                    @click.prevent="editSubvenirBesoin(data.item)"
                    class="btn btn-success rounded-0 btn-sm me-1"
                    ><i class="bi bi-pencil-square"></i
                  ></a>
                  <a class="btn btn-info rounded-0 btn-sm me-1"
                    ><i class="bi bi-info-square"></i
                  ></a>
                  <a
                    @click.prevent="deleteSubvention(data.item)"
                    class="btn btn-danger rounded-0 btn-sm me-1"
                    ><i class="bi bi-trash"></i
                  ></a>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
          <div
            class="d-flex flex-wrap justify-content-between align-items-center"
          >
            <p>Affichage de l’élément 1 à 38 de 38 éléments</p>
            <!-- <b-pagination
              :value="mxPagination.page"
              :total-rows="mxPageCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import SubvenirBesoinEdit from "@/components/espace/admin/subvenirBesoin/SubvenirBesoinEdit";

export default {
  components: {
    SubvenirBesoinEdit,
  },
  data() {
    return {
      activeSubvenirBesoin: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "SubvenirBesoins",
          active: true,
        },
      ],
    };
  },
  created() {
    this.fetchSubvenirBesoins();
  },
  computed: {
    ...mapGetters({
      subvenirbesoins: "subvenirBesoin/subvenirBesoins",
    }),
    tableFields() {
      return [
        "index",
        { key: "name", label: "Libellé" },
        // { key: 'structure', label: 'Structure' },
        "actions",
      ];
    },
    tableData() {
      return this.subvenirbesoins.map((item) => ({ ...item }));
    },
  },
  methods: {
    ...mapActions({
      fetchSubvenirBesoins: "subvenirBesoin/fetchSubvenirBesoins",
      createOrUpdateSubvenirBesoin:
        "subvenirBesoin/createOrUpdateSubvenirBesoin",
      deleteSubvenirBesoin: "subvenirBesoin/deleteSubvenirBesoin",
    }),
    editSubvenirBesoin(subvenirbesoin) {
      console.log(subvenirbesoin);
      this.activeSubvenirBesoin = { ...subvenirbesoin };
      this.$refs["SubvenirBesoinEditor"].show();
    },
    updateOrCreateSubvenirBesoin(subvenirbesoin) {
      console.log(subvenirbesoin);
      this.createOrUpdateSubvenirBesoin(subvenirbesoin).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "bottom-right",
          duration: 5000,
        });
      });
    },
    deleteSubvention(subvenirbesoin) {
      console.log(subvenirbesoin);
      this.$confirm({
        message: `Confirmez - vous la suppression?`,
        button: {
          no: "Non",
          yes: "Oui",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.deleteSubvenirBesoin(subvenirbesoin).then((data) => {
              console.log(data);
              this.$toast.success("Opération réussie!", {
                position: "bottom-right",
                duration: 5000,
              });
            });
          }
        },
      });
    },
  },
};
</script>

<style>
</style>